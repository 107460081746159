<template>
    <FormWrapper>
        <PageTitle title="Add/Update Release Note" slot="title" link="/helpContent/Add Release Notes" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-2">
                    <InputDate v-model="form.date" rules="required" label="Release Date"  />
                </div>
                <div class="col-sm-2">
                    <InputText v-model="form.version" rules="required" label="Release Version"  />
                </div>
                <div class="col-sm-6">
                    <InputTextArea v-model="form.title" rules="required" label="Release Title" />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-12">
                    <label class="InputTextArea_label_35O-v">Release Notes<span class="InputTextArea_required_1qWYF">*</span></label>
                    <EditorComponent v-model="form.note" rules="required" label="Release Notes" />
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import InputDate from 'Components/form/InputDate';
    import {addReleaseNote, getReleaseNote} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            PageTitle,
            InputDate,
            ValidationObserver
        },
        data() {
            return {
                form:{
                    date:'',
                    version:null,
                    title:null,
                    note:null
                },
                showMessage:{
                    isVisible: false,
                    message: ''
                }
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.releaseId = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getReleaseNote(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        const data={
                            releaseId: this.$route.params.id?atob(this.$route.params.id):0,
                            releaseDate: this.form.date,
                            releaseVersion: this.form.version,
                            releaseTitle: this.form.title,
                            releaseNotes: this.form.note
                        };
                        addReleaseNote(data).then((res)=>{
                            this.form = {};
                            this.form.note = '';
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.date = response.data[0].CreatedDate;
                this.form.version = response.data[0].VersionName;
                this.form.title = response.data[0].ReleaseTitle;
                this.form.note = response.data[0].Notes;
            },
        }
    }
</script>
<style lang="scss">
    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>